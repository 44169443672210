import React from 'react';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { getRegisterSocialAuthBackendUrl, SocialAuthType } from '@Components/helper/url';
import google_logo_white from '@Assets/img/logos/google_logo_light.svg';
import { ImageContainer, Root } from './GoogleButton.styles';
interface Props extends PropsWithClassName {
  type: 'button' | 'link';
  onClick?: () => void;
  children: React.ReactNode;
}
const GoogleButton = (props: Props): React.ReactElement => {
  const {
    children,
    type,
    onClick
  } = props;
  const {
    user
  } = useGlobalContext();
  const renderContent = () => {
    return <>
        <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="GoogleButton.tsx">
          <img alt="Google Logo" width={35} height={35} src={google_logo_white.src} />
        </ImageContainer>
        {children}
      </>;
  };
  if (type === 'link') {
    return <Root as="a" className={props.className} data-testid={props.dataTestId ? props.dataTestId : 'GoogleButton-root'} href={getRegisterSocialAuthBackendUrl(SocialAuthType.Google, user?.id)}>
        {renderContent()}
      </Root>;
  }
  if (type === 'button') {
    return <Root as="button" className={props.className} data-testid={props.dataTestId ? props.dataTestId : 'GoogleButton-root'} onClick={() => {
      if (onClick) {
        onClick();
      }
    }}>
        {renderContent()}
      </Root>;
  }
  return <></>;
};
export { GoogleButton };