import styled from 'styled-components';

import Text from '@Components/atoms/Text';

import { ErrorTextStyles, InputStyles } from '@Assets/styles/theme/mixins';

export const Root = styled.div`
  margin-bottom: ${(props) => props.theme.space.default_16};
`;
interface InputProps {
  readonly error: boolean;
  readonly disabled: boolean;
}
export const Input = styled.input<InputProps>`
  ${(props) => InputStyles(props.error, props.disabled)};
`;
export const ErrorText = styled(Text)`
  ${() => ErrorTextStyles()};
`;
