import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  //todo: fixed font for facebook button?
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.semibold.medium.fontSize};
  font-weight: ${(props) => props.theme.font.text.semibold.medium.fontWeight};
  line-height: ${(props) => props.theme.font.text.semibold.medium.lineHeight};
  letter-spacing: ${(props) => props.theme.font.text.semibold.medium.letterSpacing};

  cursor: pointer;
  text-align: center;
  border-radius: ${(props) => props.theme.borderRadius.button};
  border: 1px solid ${(props) => props.theme.palette.static.facebookButton.border};
  background: ${(props) => props.theme.palette.static.facebookButton.background};
  color: ${(props) => props.theme.palette.static.facebookButton.text};

  width: 100%;
  padding: 5px 17px 5px 17px;
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
`;
