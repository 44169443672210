import React from 'react';
import { ValidationMessage } from '@Api/models/ValidationErrorModel';
import InputDescription from '@Components/atoms/Form/InputDescription';
import { PropsWithClassName } from '@Components/helper';
import { TextSize } from '@Helpers/types/text';
import InputLabel from '../InputLabel';
import { ErrorText, Input, Root } from './InputPassword.styles';
interface Props extends PropsWithClassName {
  label?: string | null;
  placeholder?: string | null;
  forId: string;
  name?: string;
  value: string;
  required: boolean;
  error: boolean;
  disabled: boolean;
  onChange: (value: string) => void;
  errorText?: ValidationMessage[];
  descriptionText?: string | null;
}
const InputPassword = (props: Props): React.ReactElement => {
  const {
    label,
    placeholder,
    forId,
    required,
    name,
    error,
    onChange,
    value,
    disabled,
    errorText,
    dataTestId,
    descriptionText
  } = props;
  const testId = dataTestId ? dataTestId : 'InputPassword';
  return <Root className={props.className} data-testid={testId + '-root'} data-sentry-element="Root" data-sentry-component="InputPassword" data-sentry-source-file="InputPassword.tsx">
      {label && <InputLabel disabled={disabled} error={error} required={required} forId={forId}>
          {label}
        </InputLabel>}
      <Input disabled={disabled} error={error} name={name ? name : forId} onChange={event => onChange(event.currentTarget.value)} id={forId} value={value} type="password" placeholder={placeholder ?? undefined} required={required} data-testid={testId + '-input'} data-sentry-element="Input" data-sentry-source-file="InputPassword.tsx" />
      {descriptionText && <InputDescription>{descriptionText}</InputDescription>}
      {error && errorText?.map((message: ValidationMessage) => <ErrorText key={message.key} size={TextSize.Small} dataTestId={testId + '-error'}>
            {message.message}
          </ErrorText>)}
    </Root>;
};
export { InputPassword };