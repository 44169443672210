import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  width: 35px;
  height: 35px;

  path {
    fill: ${(props) => props.theme.palette.static.discordButton.text};
  }
`;
